import React, {useCallback, useRef} from 'react';
import './homePage.module.scss';
import styles from './homePage.module.scss'
import lenaAboutMeSectionImg from '../../images/photos/lena-about-me-section.png'
import lenaAboutMeSectionCreattionDemoImg from '../../images/icons/creattion-demo-about-me.svg'
import motivationSectionIcon_1 from '../../images/icons/reasons-to-practice_1.svg'
import motivationSectionIcon_2 from '../../images/icons/reasons-to-practice_2.svg'
import motivationSectionIcon_3 from '../../images/icons/reasons-to-practice_3.svg'
import motivationSectionIcon_4 from '../../images/icons/reasons-to-practice_4.svg'
import motivationSectionIcon_5 from '../../images/icons/reasons-to-practice_5.svg'
import motivationSectionIcon_6 from '../../images/icons/reasons-to-practice_6.svg'
import motivationSectionIcon_7 from '../../images/icons/reasons-to-practice_7.svg'
import motivationSectionCreattionDemoImg from '../../images/icons/creattion-demo-motivation.svg'
import youCanWithMeSectionImg from '../../images/photos/lena-you-can-with-me-section.png'
import exercisesVideoSectionCreattionDemoImg from '../../images/icons/creattion-demo-exercises-video.svg'
import swiperItemUserLogoSvetlana from '../../images/photos/avatar-svetlana.png'
import swiperItemUserLogoYana from '../../images/photos/avatar-yana.png'
import swiperItemUserLogoOleksandra from '../../images/photos/avatar-oleksandra.png';
import swiperItemUserLogoMaria from '../../images/photos/avatar-maria.png';
import swiperItemUserLogoSola from '../../images/photos/avatar-sola.png';
import swiperItemUserLogoNika from '../../images/photos/avatar-nika.png';
import swiperItemUserLogoYana2 from '../../images/photos/avatar-yana2.png';
import swiperItemUserLogoTanya from '../../images/photos/avatar-tanya.png';
import swiperItemUserLogoInna from '../../images/photos/avatar-inna.png';
import swiperItemUserLogoLyudmila from '../../images/photos/avatar-lyudmila.png';
import swiperItemResult_1 from '../../images/photos/result_1.jpg'
import swiperItemResult_2 from '../../images/photos/result_2.jpg'
import swiperItemResult_3 from '../../images/photos/result_3.jpg'
import swiperItemResult_4 from '../../images/photos/result_4.jpg'
import swiperItemResult_5 from '../../images/photos/result_5.jpg'
import video from '../../images/video/final.webm'
import video2 from '../../images/video/final.mp4'
import {TariffsSlider} from "./components/tariffsSlider/tariffsSlider";
import CollapsibleQuestionOftenAskedSection
    from "./components/collapsibleQuestionOftenAsked/collapsibleQuestionOftenAsked";
import collapsibleQuestionOftenAskedSectionCreattionDemoImg
    from '../../images/icons/creattion-demo-collapsible-question-often-asked.svg'
import FeedbackForm from "./components/feedbackForm/feedbackForm";

// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from "swiper/modules";

function HomePage() {
    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    const swiperFeedback = [{
        name: "Світлана",
        logo: swiperItemUserLogoSvetlana,
        result: "-8 кг, вирівнялася лопатка крилоподібна, менше турбують головні болі, з’явилася більша гнучкість у тілі, воно стало міцнішим і здоровішим, стала більш стресостійка, навчилася розслаблятися/перемикатися/ абстрагуватися, дякувати собі. А ще все роблю із задоволенням.",
        liked: "Увесь контент, але найбільше зарядки, мінітренування, підкачки спочатку на різні частини тіла, а потім на все тіло, фотоприклади тарілок, позитивна енергія, сподобалися відео з краєвидами.",
        willContinue: "Так, планую продовжити, бо це стало вже моїм стилем життя."
    }, {
        name: "Яна",
        logo: swiperItemUserLogoYana,
        result: "Кожного року я ходила на масажі, бо з дитинства спина має цілий «букет болячок», але так, як допомогли вправи і проста зарядка від Лени нічого не допомагало.",
        liked: "На курсі відчувається суцільна тепло, турбота, позитив і підтримка від Лени, що разом з професіоналізмом надихають займатись собою і отримувати чудові результати 😍",
        willContinue: "Я вже мабудь на пʼятому потоці, і сумнівів немає, що буду подовжувати далі, бо це реальна любов ❤️"
    }, {
        name: "Олександра",
        logo: swiperItemUserLogoOleksandra,
        result: "Без болю в колінах піднімалась і спускалась сходами; рівна постава в 56 років; нахиляючись вільно дістаю долонями підлогу, не згинаючи колін; без відпочинку й задихки піднімаюсь на 5 поверх. А ще без проблем почала збалансовано харчуватись і мити воду зранку й протягом дня.",
        liked: "На Вашому курсі почувалася давно ще з 1 потоку. Подобаються зарядки з записів, міні тренування, розтяжки.",
        willContinue: "Звичайно, буду продовжувати брати участь 😍"
    }, {
        name: "Марія",
        logo: swiperItemUserLogoMaria,
        result: "Лено! Я займаюся з тобою понад 2 роки. Мені 47 років. Майже відразу відчула результат - пішов біль із кульшового суглоба, майже перестала вийдуватися до попереку. Все тіло підтягнулось. Руки за спиною стали добре замищатися.",
        liked: "Дуже сподобався відповідальний підхід, насичений та різноманітний. Люблю зарядки, роблю щодня без вихідних. Також ЛФК та розтяжка особливо подобаються, кожен день є тренування, можна підібрати за своїм станом та залишатися в тонусі.",
        willContinue: "Планую продовжувати брати участь у наступних курсах, мене все влаштовує."
    }, {
        name: "Соля",
        logo: swiperItemUserLogoSola,
        result: "Зникли болі в спині, до профілактики дистанційно з Леною, з’явилось більше руху. Більше не болять ноги після зарядки, краще стали розтягуватись.",
        liked: "Подобаються всі зарядки ранкові, які роблю майже з усіма учасниками. Люблю розтяжки та ЛФК.",
        willContinue: "Звичайно, що планую продовжувати курс."
    }, {
        name: "Ніка",
        logo: swiperItemUserLogoNika,
        result: "Хочу подякувати тобі за твою роботу! Я з тобою вже 3 потік, завжди є активність в чатах і зарядках. Роблю все, що ти рекомендуєш. Мене раніше турбували болі в спині, але з часом стало легше.",
        liked: "Подобаються всі вправи, навіть прості, але дієві. Зарядки зранку заряджають і допомагають бути в тонусі весь день. Особливо подобаються короткі 3-хвилинні тренування, що можуть дати результат.",
        willContinue: "Я планую брати участь в наступних потоках! Я тільки чекаю наступний потік!"
    }, {
        name: "Яна",
        logo: swiperItemUserLogoYana2,
        result: "Зникли болі в спині, покращився стан тіла - вага підтримується - не прибавляю, зникли судоми на ногах!",
        liked: "Твій підхід, твоя енергія, мотивація, підтримка.",
        willContinue: "Я продовжую з тобою займатися!!!"
    }, {
        name: "Таня",
        logo: swiperItemUserLogoTanya,
        result: "За майже два роки участі я позбулася болю в спині та значно зміцнила своє тіло. Тепер я завжди почуваю себе в тонусі.",
        liked: "Обожнюю всі вправи, особливо на поставу та ЛФК. Зарядки з Леною роблять мене сильнішою!",
        willContinue: "Так, буду продовжувати участь в наступних курсах."
    }, {
        name: "Інна",
        logo: swiperItemUserLogoInna,
        result: "Отримала – ще більше любові до себе. Тіло відчулось легше, а бажання – піднялось. Ми продовжуємо працювати разом з чудовою командою.",
        liked: "Короткі тренування - відчуваю, що вони справді допомагають.",
        willContinue: "Продовжую, я щаслива Людина – зацікавлена у подальшій участі."
    }, {
        name: "Людмила",
        logo: swiperItemUserLogoLyudmila,
        result: "Результат - менше турбували болі в спині, довго на ногах не могла стояти. Але тепер стало легше, спина менше турбує.",
        liked: "Курс турбується про твоє здоров'я вчить дбати про себе і не забувати про себе. Я почуваю себе набагато краще.",
        willContinue: "Продовжую далі!"
    }];

    const columns = [[{
        icon: motivationSectionIcon_1,
        text: [{text: 'позбудешся болів в тілі, ', bold: false}, {text: 'доведено десятками тисяч учнів', bold: true},],
    }, {
        icon: motivationSectionIcon_2,
        text: [{text: 'схуднеш без ', bold: false}, {text: 'шкоди для здоров’я', bold: true},],
    },], [{
        icon: motivationSectionIcon_3,
        text: [{text: 'покращиш поставу ', bold: false}, {text: 'з точки зору реабілітації', bold: true},],
    }, {
        icon: motivationSectionIcon_4,
        text: [{text: 'станеш гнучким, сядеш на шпагат ', bold: false}, {text: 'без болі', bold: true},],
    }, {
        icon: motivationSectionIcon_5,
        text: [{text: 'навчишся ', bold: false}, {text: 'відчувати своє тіло', bold: true},],
    },], [{
        icon: motivationSectionIcon_6,
        text: [{text: 'підтягнеш фігуру ', bold: false}, {text: 'тренуючись із задоволенням', bold: true},],
        extraClass: styles.col3,
    }, {
        icon: motivationSectionIcon_7,
        text: [{text: 'здорове харчування ', bold: false}, {text: 'без обмежень та стресів', bold: true},],
        extraClass: styles.col3,
    },],];

    return (<div className={styles.homePage}>
        <div className={`${styles.heroSection} container`}>
            <video muted playsInline={true} autoPlay={true} controls={false} loop id="bg-video">
                <source src={video} type="video/webm"/>
                <source src={video2} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
            <h1 className={`${styles.heroSection__h1} montserrat-54-black`}>ПЛАТФОРМА ЗДОРОВИХ І ЩАСЛИВИХ ЛЮДЕЙ</h1>
            <p className={`${styles.heroSection__subtitle} raleway-24-regular`}>Місце, де піклуються про тебе</p>
        </div>
        <div className={`${styles.aboutMeSection} container`} id="aboutMe">
            <img className={styles.img} src={lenaAboutMeSectionImg} alt="LenaAboutMeSectionImg"/>
                <div className={styles.description}>
                    <h2 className={`${styles.description__h2} montserrat-36-black`}>ЗІ МНОЮ ТВОЄ ТІЛО В БЕЗПЕЦІ</h2>
                    <p className={`raleway-16-regular`}>
                        Мене звати Лєна.<span className={`raleway-16-bold`}> Мій досвід роботи більше 10 років. </span>
                        Навчаюся на факультеті фізично-спортивної реабілітації в Україні,
                        <span className={`raleway-16-bold`}> здобуваю спеціальність тренера в коледжі США,&#32;</span>
                        а також проходжу багато інших курсів для саморозвитку.
                        <br/><br/>
                        <span className={`raleway-16-bold`}>Матеріал, підхід, подача мого курсу перевірені та
                        затверджені лікарем-травматологом та реабілітологом.</span>
                        <br/><br/>
                        Я є амбасадором зарядок. Сотні тисяч людей отримали мотивацію та покращили своє самопочуття.
                        На перше місце завжди ставлю твоє здоров’я, а не результат будь якою ціною.
                    </p>
                    <img className={styles.img} src={lenaAboutMeSectionCreattionDemoImg} alt="creattionDemo"/>
                </div>
            </div>
            <div className={`${styles.motivationSection} container`} id="motivation">
                <h2 className={`${styles.motivationSection__h2} montserrat-36-black`}>ЛОВИ МОТИВАЦІЮ</h2>
                <div className={styles.reasons}>
                    {columns.map((column, colIndex) => (<div className={styles.col} key={colIndex}>
                            {column.map((item, index) => (<div
                                    className={`${styles.reasons__item} ${item.extraClass || ''}`}
                                    key={index}
                                >
                                    <img className={styles.img} src={item.icon} alt="icon"/>
                                    <p className="raleway-16-regular">
                                        {item.text.map((part, i) => part.bold ? (
                                            <span className="raleway-16-bold" key={i}>
                    {part.text}
                  </span>) : (part.text))}
                                    </p>
                                </div>))}
                        </div>))}
                </div>
                <img className={styles.img} src={motivationSectionCreattionDemoImg} alt="creattionDemo"/>
            </div>
            <div className={`${styles.youCanWithMeSection} container`}>
                <h2 className={`${styles.h2} montserrat-36-black`}>ХОЧЕШ СТАНУ ТВОЇМ ТРЕНЕРОМ?</h2>
                <div className={styles.description}>
                    <div className={styles.imgContainer}>
                        <img className={styles.img} src={youCanWithMeSectionImg} alt="youCanWithMeSectionImg"/>
                        <div className={styles.backgroundImg}></div>
                    </div>
                    <div className={`${styles.subtitles}`}>
                        <p className={`${styles.subtitles__item} raleway-16-regular`}>
                            Допоможу тобі любити робити щоденну ранкову
                            <span className={`raleway-16-bold`}> зарядку</span>
                        </p>
                        <p className={`${styles.subtitles__item} raleway-16-regular`}>
                            Навчу створювати хороший
                            <span className={`raleway-16-bold`}> нейромʼязовий звʼязок</span>
                            , для ефективних тренувань
                        </p>
                        <p className={`${styles.subtitles__item} raleway-16-regular`}>
                            Разом зі мною ти
                            <span className={`raleway-16-bold`}> подружишся з фізичним навантаженням </span>
                            та почнем отримувати від нього задоволення
                        </p>
                        <p className={`${styles.subtitles__item} raleway-16-regular`}>
                            Без стресу, без болі, без виснажливих до нудоти занять ти
                            <span className={`raleway-16-bold`}> зможеш отримати здорове та красиве тіло</span>
                        </p>
                        <p className={`${styles.subtitles__item} raleway-16-regular`}>
                            Ретельно підбираю та
                            <span className={`raleway-16-bold`}> фільтрую кожному вправу, </span>
                            завжди ставлю на 1 місце здоровʼя
                        </p>
                        <p className={`${styles.subtitles__item} raleway-16-regular`}>
                            Я підготувала для тебе лекцію про харчування та прості,
                            <span className={`raleway-16-bold`}> смачні відео-рецепти</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className={`${styles.exercisesVideoSection} container`} id="exercisesVideo">
                <h2 className={`${styles.exercisesVideoSection__h2} montserrat-36-black`}>
                    ЗАПРОШУЮ ЗРОБИТИ ЗІ МНОЮ ЗАРЯДКУ ПРЯМО ЗАРАЗ, СПРОБУЙ!
                </h2>

                <div className={`${styles.exercisesVideoSection__videoContainer}`}>
                    <iframe className={`${styles.video}`}
                            src="https://www.youtube.com/embed/RsKPiv53HL8?si=w99Koq7Cpl4zSZmS"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen>
                    </iframe>
                </div>
                <img className={styles.img} src={exercisesVideoSectionCreattionDemoImg} alt="creattionDemo"/>
            </div>
            <div className={`${styles.studentsFeedbackSection} container`} id="feedback">
                <h2 className={`${styles.studentsFeedbackSection__h2} montserrat-36-black`}>ВІДГУКИ УЧНІВ</h2>
                <div className={styles.swiperContainer}>
                    <Swiper
                        ref={sliderRef}
                        className={styles.swiper}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        spaceBetween={30}
                        breakpoints={{
                            769: {slidesPerView: 2}, 768: {slidesPerView: 1},
                        }}
                    >
                        {swiperFeedback.map((item, index) => (<SwiperSlide key={index} className={styles.swiper__item}>
                                <div className={styles.userInfo}>
                                    <img src={item.logo} alt={`${item.name} logo`} className={styles.img}/>
                                    <span className={`${styles.name} raleway-24-regular`}>{item.name}</span>
                                </div>
                                <h3 className={`${styles.question} raleway-16-bold`}>Який результат ти отримала?</h3>
                                <p className={`${styles.answer} raleway-16-regular`}>{item.result}</p>
                                <h3 className={`${styles.question} raleway-16-bold`}>Що сподобалось?</h3>
                                <p className={`${styles.answer} raleway-16-regular`}>{item.liked}</p>
                                <h3 className={`${styles.question} raleway-16-bold`}>Чи плануєш продовжувати брати
                                    участь?</h3>
                                <p className={`${styles.answer} raleway-16-regular`}>{item.willContinue}</p>
                            </SwiperSlide>))}
                    </Swiper>
                    <div className={styles.prevArrow} onClick={handlePrev}/>
                    <div className={styles.nextArrow} onClick={handleNext}/>
                </div>
            </div>
            <div className={`${styles.resultsSection} container`}>
                <h2 className={`${styles.resultsSection__h2} montserrat-36-black`}>РЕЗУЛЬТАТИ</h2>
                <div className={styles.swiperContainer}>
                    <Swiper
                        className={styles.swiper}
                        modules={[Pagination]}
                        slidesPerView={2.5}
                        breakpoints={{
                            768: {
                                slidesPerView: 5,
                            },

                        }}
                    >
                        <SwiperSlide className={styles.swiper__item}>
                            <img src={swiperItemResult_1} className={styles.img}/>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper__item}>
                            <img src={swiperItemResult_2} className={styles.img}/>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper__item}>
                            <img src={swiperItemResult_3} className={styles.img}/>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper__item}>
                            <img src={swiperItemResult_4} className={styles.img}/>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper__item}>
                            <img src={swiperItemResult_5} className={styles.img}/>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className={`${styles.tariffsSection} container`} id="tariffs">
                <h2 className={`${styles.tariffsSection__h2} montserrat-36-black`}>
                    ОБЕРІТЬ ТАРИФ, ЯКИЙ ПІДХОДИТЬ САМЕ ТОБІ
                </h2>
                <TariffsSlider/>
            </div>
            <div className={`${styles.popularQuestionsSection} container`} id="popularQuestions">
                <h2 className={`${styles.popularQuestionsSection__h2} montserrat-36-black`}>
                    ВІДПОВІДІ НА ПОПУЛЯРНІ ЗАПИТАННЯ
                </h2>
                <div className={styles.popularQuestionsSection__content}>
                    <CollapsibleQuestionOftenAskedSection/>
                    <img className={styles.img} src={collapsibleQuestionOftenAskedSectionCreattionDemoImg}
                         alt="creattionDemo"
                    />
                </div>
            </div>
            <div className={`${styles.feedbackSection} container`}>
                <div className={styles.feedbackSection__content}>
                    <h2 className={`${styles.feedbackSection__content__h2} montserrat-36-black`}>
                        ЗВОРОТНІЙ ЗВ'ЯЗОК
                    </h2>
                    <p className={`${styles.feedbackSection__content__description} raleway-16-regular`}>
                        Мені дуже важливий зворотній звʼязок, тому якщо в тебе виникли якісь труднощі чи запитання, або
                        ти маєш відгук, пропозицію для вдосконалення платформи - будь ласка залиш свій коментар, дякую.
                    </p>
                </div>
                <div className={`${styles.feedbackSection__form} form`}>
                    <FeedbackForm/>
                </div>
            </div>
        </div>)
}

export default HomePage;
